@import '../../theme/variables.scss';
.login-bg {
    background: url('../../../public/images/login-bg.webp');
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .login-form-container {
        max-width: 380px;
    }
}
.login-btn {
    height: 4rem;
    font-size: 1.375rem !important;
    border-radius: 0.7rem !important;
}
.tx-primary {
    color: $primary !important;
}

.text-cardia-health {
    font-size: 1.15rem;
}
.main-logo {
    width: 15%;
}
.logo-slogan {
    width: 30%;
}
