@import './variables.scss';

.MuiTextField-root.login-field {
    .MuiFormLabel-root {
        padding-left: 10px !important;
        font-family: 'Inter' !important;
        line-height: 1 !important;
        font-size: 1.125rem !important;
    }
    .MuiFilledInput-root {
        background: #fff !important;
        border-radius: 0.7rem !important;
        font-size: 1rem !important;
        border: 1px solid #d1d5db;
        font-family: 'Inter', 'sans serif' !important;
        height: 3.5rem;
        .MuiFilledInput-input {
            padding: 27px 12px 10px 18px !important;
        }
    }
}

.MuiInputLabel-filled {
    font-size: 0.9rem;
}
.MuiButton-root {
    box-shadow: none !important;
}

.btn-primary {
    background-color: $primary !important;
    color: #fff !important;
    &:disabled {
        background-color: $light-gray-1 !important;
        color: #fff !important;
        pointer-events: initial !important;
        cursor: not-allowed !important;
    }
}
.btn-white-outline {
    background-color: #f9fafb !important;
    color: $gray !important;
    border: 1px solid $light-gray-1 !important;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 5%) !important;
    &:disabled {
        background-color: $light-gray-1 !important;
        color: #fff !important;
        pointer-events: initial !important;
        cursor: not-allowed !important;
    }
}

.btn-secondary {
    background-color: $purple !important;
    color: #fff !important;
    &:disabled {
        background-color: $light-gray-1 !important;
        color: #fff !important;
        pointer-events: initial !important;
        cursor: not-allowed !important;
    }
}
.btn-light {
    background-color: $light-purple !important;
    color: $indigo-700 !important;
    &:disabled {
        background-color: $light-gray-1 !important;
        color: #fff !important;
        pointer-events: initial !important;
        cursor: not-allowed !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}
.MuiFormHelperText-root {
    font-size: 0.9rem !important;
}
.MuiFormLabel-root {
    color: #111827 !important;
    opacity: 50% !important;
}
.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-root.Mui-error {
    color: #111827 !important;
    opacity: 100% !important;
}
.MuiLoadingButton-root,
.MuiButton-root {
    text-transform: capitalize !important;
}
.cursor-default {
    cursor: default !important;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
.dashboard-accordion {
    .MuiAccordionSummary-root {
        min-height: 40px !important;
        .MuiAccordionSummary-content {
            margin: 0px !important;
        }
    }
}
@media only screen and (max-width: 1200px) {
    body {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 600px) {
    .MuiPaper-root.MuiPaper-elevation.MuiDialog-paperWidthSm[aria-labelledby='small-modal'] {
        max-width: 522px !important;
        min-width: 522px !important;
        border-radius: 0.5rem !important;
    }
}
button[aria-label='close'].MuiButtonBase-root.MuiIconButton-root {
    color: #4b5563;
}
.MuiDialogContent-root {
    border-top: none !important;
}

.loader {
    text-align: center;
    img {
        width: 50px;
        margin: 10px;
    }
}

.form-error-message {
    color: $red-600;
    padding-top: 1px;
    padding-bottom: 2px;
    font-size: 12px;
}
