@import '../../theme/variables.scss';

.color-gray {
    color: $gray-600;
    cursor: default;
}
.profile-title {
    margin-bottom: 0.5rem;
    color: #1f2937;
    font-size: 0.9rem;
    cursor: default;
}
