@import './../theme/theme.scss';
@import './../theme/variables.scss';

.label-wrapper {
    color: $gray-800;
    background-color: $light-purple;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    border-radius: '7%';
}
.list-inside-styled {
    margin-bottom: 0;
    li {
        list-style-type: disc !important;
    }
}
