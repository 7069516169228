@import '../../../theme/variables.scss';
.action-pills {
    background: $white;
    border: 1px solid;
    border-color: $gray-200;
    border-radius: 80px;
    text-align: center;
    width: max-content;
    padding: 2px 10px;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 10px;
    color: $gray-900;
    cursor: pointer;
    display: flex;
    &.active {
        background-color: $purple !important;
        color: #fff;
        &.flag-abnormal {
            background: $purple-200;
            outline-offset: 1px;
            outline: 1px solid $indigo-700;
        }
    }
    &.flag-abnormal {
        background: $purple-200;
    }
    &.mild {
        border-color: $indigo-300;
        &.flag-mild {
            background: $purple-200;
        }
        &.flag-abnormal {
            background: $purple-200;
        }
        &.active {
            border: 1px solid;
            border-color: $purple;

            &.flag-none {
                outline-offset: 1px;
                outline: 1px solid $indigo-300;
            }
            &.flag-mild {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
            &.flag-abnormal {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
        }
    }
    &.moderate {
        border-color: $indigo-400;
        &.flag-moderate {
            background: $purple-200;
        }
        &.flag-abnormal {
            background: $purple-200;
        }
        &.active {
            border: 1px solid;
            border-color: $purple;

            &.flag-none {
                outline-offset: 1px;
                outline: 1px solid $indigo-400;
            }
            &.flag-abnormal {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
            &.flag-moderate {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
        }
    }
    &.severe {
        border-color: $indigo-700;
        &.flag-severe {
            background: $purple-200;
        }
        &.flag-abnormal {
            background: $purple-200;
        }
        &.active {
            border: 1px solid;
            border-color: $purple;
            &.flag-none {
                outline-offset: 1px;
                outline: 1px solid $purple;
            }
            &.flag-abnormal {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
            &.flag-severe {
                outline-offset: 1px;
                outline: 1px solid $indigo-700;
            }
        }
    }
}

span.pill-trim {
    width: 35px;
    overflow: hidden;
    display: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
}
