.dummy-chart {
    width: 100%;
    min-width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dummy-line {
        width: 90%;
        height: 3px;
        border-radius: 60px;
        background: #4f46e5;
    }
}
