@import '../../theme/variables.scss';
.main-appbar.MuiAppBar-colorPrimary {
    background: $primary !important;
    box-shadow: none !important;
    .MuiContainer-root {
        .MuiToolbar-root {
            justify-content: space-between;
        }
    }
}
.MuiMenuItem-root {
    font-family: 'Inter' !important;
    font-size: 0.9rem !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

/* app-bar.scss */
body.modal-open {
    overflow: hidden;
    padding-right: 17px; /* Adjust this value based on your scrollbar width */
}
