.vital-switch-btn > button.MuiToggleButton-root[aria-pressed='true'] {
    &:hover {
        border: 1px solid #4f46e5;
        background: #4f46e5;
        color: #fff;
    }
    border: 1px solid #4f46e5;
    background: #4f46e5;
    color: #fff;
    width: 100px;
    height: 30px;
    font-size: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
.vital-switch-btn > button.MuiToggleButton-root[aria-pressed='false'] {
    background: #ffffff;
    border: 1px solid #4f46e5;
    color: #4f46e5;
    width: 100px;
    height: 30px;
    font-size: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.vital-switch-btn > button.MuiToggleButton-root.Mui-disabled {
    background: #f0f1f3;
    border: 1px solid #f0f1f3;
    color: #d9dce1;
    width: 100px;
    height: 30px;
    font-size: 12px;
}

input.small-rounded {
    width: 55px;
    border-radius: 30px;
    height: 25px;
    outline: none;
    padding: 10px;
    border: 1px solid #d1d5db;
    font-size: 0.85rem;
}
input.medium-rounded {
    width: 80px;
    border-radius: 30px;
    height: 25px;
    outline: none;
    padding: 10px;
    border: 1px solid #d1d5db;
    font-size: 0.85rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
span.name-chip {
    font-size: 0.85rem;
    background: #f7f8f9;
    border: 1px solid #d1d5db;
    border-radius: 50px;
    padding: 2px 10px;
}

.MuiSelect-select[aria-labelledby='small-select small-select'] {
    padding: 0px 30px 0px 10px !important;
    border: 1px solid #4f46e5;
    color: #4f46e5;
    border-radius: 50px !important;
    font-weight: 500;
    &:focus {
        border-radius: 50px !important;
        border-color: #4f46e5 !important;
        box-shadow: none !important;
    }
}
