@import '../../../../theme/variables.scss';

.interval-class .MuiToggleButtonGroup-groupedVertical,
.interval-class .MuiToggleButton-root {
    background: $white;
    border: 1px solid !important;
    border-color: $gray-200 !important;
    border-radius: 80px !important;
    text-align: center !important;
    width: max-content !important;
    min-width: 40px;
    padding: 2px 6px 2px !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    margin: 1px 2px 1px !important;
    color: $gray-900 !important;
    cursor: pointer !important;
    display: flex !important;
    flex: 1 1 0 !important;
}

.interval-class .MuiToggleButtonGroup-groupedVertical.Mui-selected,
.interval-class .Mui-selected {
    color: #fff !important;
    background-color: $purple !important ;
}

.interval-class .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $gray-200 !important;
    border-width: 1.5px !important;
}

.interval-class .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $purple !important;
}
