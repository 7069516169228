.change-password {
    label {
        display: none !important;
    }
    .MuiFormHelperText-contained {
        margin-left: 0px;
        margin-right: 14px;
    }
}
.MuiTextField-root.login-field.change-password .MuiFilledInput-root .MuiFilledInput-input {
    padding: 10px 12px 10px 18px !important;
    height: 26px;
}

.MuiTextField-root.login-field.change-password {
    height: 3rem;

    .MuiInputBase-root.MuiFilledInput-root {
        border-radius: 0.5rem !important;
    }
}

label.change-pass-label {
    color: #4b5563;
    margin-bottom: 0.3rem;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.9rem;
}
