@import '../../../../theme/variables.scss';

.input-chip {
    border: 1px solid;
    border-color: $gray-200;
    border-radius: 80px;
    text-align: center;
    width: max-content;
    padding: 2px 10px;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    background-color: $purple !important;
    color: #fff !important;
}

.medication-class .MuiToggleButtonGroup-groupedVertical,
.medication-class .MuiToggleButton-root {
    background: $white;
    border: 1px solid !important;
    border-color: $gray-200 !important;
    border-radius: 80px !important;
    text-align: center !important;
    width: max-content !important;
    min-width: 40px;
    padding: 2px 10px !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
    color: $gray-900 !important;
    cursor: pointer !important;
    display: flex !important;
    flex: 1 1 0 !important;
    // &.active {
    //     background-color: $purple !important ;
    //     color: $purple !important;
    // }
}

.medication-class .MuiToggleButtonGroup-groupedVertical.Mui-selected,
.medication-class .Mui-selected {
    color: #fff !important;
    background-color: $purple !important ;
}

.medication-class .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $gray-200 !important;
    border-width: 1.5px !important;
}

.medication-class .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $purple !important;
}

// .MuiChip-label
.medication-multiselect .MuiChip-root {
    color: #fff !important;
    background-color: $purple !important ;
}

.medication-multiselect .MuiChip-deleteIcon {
    color: $gray-200 !important;
}
