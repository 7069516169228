@import '../../theme/variables.scss';

.appointment-reason {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 230px;
    cursor: pointer;
}
p.MuiTablePagination-selectLabel {
    padding-right: 10px;
    font-family: 'Inter';
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
    align-items: baseline !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    justify-content: space-between !important;
    border-top: 1px solid #e5e7eb;
    padding-left: 0;
    padding-right: 0;
    // position: absolute;
    // width: 100%;
    // max-width: 1140px;
    // bottom: 0;
}
.MuiTablePagination-root {
    border-bottom: 0 !important;
}
// .MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select{
// display: none !important;
// }
.new-tag {
    background: $light-purple;
    color: $purple;
    width: 50px;
    height: 24px;
    text-align: center;
    padding: 3px 5px;
    border-radius: 20px;
    font-size: 0.75rem;
}
.BaseBadge-root {
    width: fit-content;
}
.active-page {
    color: $primary;
    font-weight: 600;
    border-top: 2px solid;
    margin-top: -1px;
}
.page-number {
    font-size: 1rem;
    padding: 15px;
}

input[type='search']::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    background: red;
}
input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    right: -5px;
    // border-radius: 50rem;
    background: url('https://ca-web.appstudiointernal.ca/images/clear-icon.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 1;
    // pointer-events: none;
}

.MuiTablePagination-spacer {
    flex: initial !important;
}
