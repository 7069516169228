$primary: #3f48cc;
$secondary: #fff;
$border-radius: 0.5rem;
$white: #ffffff;
$light-gray: #e4e7ea;
$teal: #27aae1;
$dark-blue: #1875bc;
$black: #0a1f37;
$black-200: #1f2937;
$gray: #374151;
$light-gray-1: #d1d5db;
$gray-50: #f7f8f9;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #fcfcfb;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #1c1e21bd;
$gray-800: #2b2b2b;
$gray-900: #111827;
$light-purple: #e0e7ff;
$purple: #4f46e5;
$purple-100: #edecfccc;
$purple-200: #4f46e552;
$purple-500: rgba(79, 70, 229, 0.2);
$white-transparent: rgba(255, 255, 255, 0);
$indigo-25: #f4f6ff;
$indigo-50: #e9eeff;
$indigo-100: #b4b0fe;
$indigo-300: #a5b4fc;
$indigo-400: #8083f2;
$indigo-500: #6366f1;
$indigo-700: #4338ca;
$green-500: #10b981;
$green-transparent: rgba(16, 185, 129, 0.2);
$blue-100: #d1eaff;
$blue-500: #0ea5e9;
$rose-400: #f6657e;
$orange-500: #f97316;
$red-500: #ef4444;
$red-600: #dc2626;

:export {
    darkGray: $gray;
    lightGray1: $light-gray-1;
    gray50: $gray-50;
    gray100: $gray-100;
    gray200: $gray-200;
    gray300: $gray-300;
    gray500: $gray-500;
    gray600: $gray-600;
    gray700: $gray-700;
    gray800: $gray-800;
    gray900: $gray-900;
    black100: $black;
    black200: $black-200;
    white100: $white;
    whiteTransparent: $white-transparent;
    lightPurple: $light-purple;
    purple: $purple;
    purple100: $purple-100;
    purple200: $purple-200;
    purple500: $purple-500;
    indigo25: $indigo-25;
    indigo50: $indigo-50;
    indigo100: $indigo-100;
    indigo300: $indigo-300;
    indigo400: $indigo-400;
    indigo500: $indigo-500;
    indigo700: $indigo-700;
    green500: $green-500;
    greenTransparent: $green-transparent;
    blue100: $blue-100;
    blue500: $blue-500;
    rose400: $rose-400;
    orange500: $orange-500;
    red500: $red-500;
    red600: $red-600;
}
