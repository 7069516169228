@import './theme/theme.scss';
@import '@aws-amplify/ui-react/styles.css';

body {
    margin: 0;
    font-family: 'Inter', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black;
}

.MuiTypography-root {
    font-family: 'Inter', sans-serif !important;
}
.MuiButton-root {
    font-family: 'Inter', sans-serif !important;
}

.popup-chart-loader {
    height: 345px;
}
.dashboard-chart-loader {
    height: auto;
}

.small-chart {
    width: 200px;
    min-width: 200px;
    height: 'fit-content';
    min-height: 132px;
}
.pdf-page {
    position: absolute;
    left: 5%;
    height: auto;
    // overflow: auto;
    padding: 10px;
}
.pdf-page-navigation {
    position: absolute;
    right: 25px;
    bottom: 25px;
    display: grid;
    justify-items: center;
    cursor: pointer;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
}

.MuiFilledInput-input:-webkit-autofill {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.amplify-tabs {
    display: none;
}

[data-amplify-authenticator] {
    background: url('../public/images/login-bg.webp');
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .login-form-container {
        max-width: 380px;
    }
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
}

.amplify-button[data-variation='primary'] {
    height: 4rem;
    font-size: 1.375rem !important;
    border-radius: 0.7rem !important;
    background-color: $primary !important;
}

.amplify-input {
    background: #fff !important;
    border-radius: 0.7em !important;
    font-size: 1rem !important;
    border: 1px solid #d1d5db;
    font-family: 'Inter', 'sans serif' !important;
    height: 3.5rem;
}

.amplify-input[type='password'] {
    border-radius: 0.7em 0 0 0.7rem !important;
    border-right: none !important;
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    padding-right: 13px;
}

.amplify-button[role='switch'] {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    display: flex;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
}

.amplify-field-group__outer-end {
    background: #fff !important;
    border: 1px solid #d1d5db !important;
    border-left: none !important;
    border-radius: 0 0.7em 0.7rem 0 !important;
    font-family: Inter, 'sans serif' !important;
    font-size: 1rem !important;
    height: 3.5rem;
}

.amplify-flex.amplify-field-group.amplify-field-group--horizontal {
    align-items: center;
    white-space: nowrap;
}

/* page break for print pdf */
@media print {
    .pagebreak {
        page-break-before: always;
    }
}
