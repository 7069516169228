@import './theme/variables.scss';

.fw-bold {
    font-weight: 600 !important;
}
.fw-normal {
    font-weight: 500 !important;
}
.fw-light {
    font-weight: 400 !important;
}
.fs-7 {
    font-size: 0.9rem !important;
}
.fs-8 {
    font-size: 0.875rem !important;
}
.fs-9 {
    font-size: 0.75rem !important;
}
.fs-10 {
    font-size: 0.7rem !important;
}
.bg-gray-1000 {
    background: #e5e7eb !important;
}
.bg-indigo-50 {
    background: $indigo-50 !important;
}
.gray-900 {
    color: #111827 !important;
}
.gray-500 {
    color: $gray-500 !important;
}
.gray-600 {
    color: #4b5563 !important;
}
.gray-400 {
    color: #9ca3af !important;
}
.gray-100 {
    color: #f3f4f6 !important;
}
.gray-900 {
    color: #111827 !important;
}
.bg-gray-100 {
    background: #f7f8f9 !important;
}
.w-25 {
    width: 25% !important;
}
.w-30 {
    width: 30% !important;
}
.w-35 {
    width: 35% !important;
}
.w-39 {
    width: 39% !important;
}
.w-61 {
    width: 61% !important;
}
.w-60 {
    width: 70% !important;
}
.w-65 {
    width: 65% !important;
}
.w-75 {
    width: 75% !important;
}
.color-primary {
    color: $primary;
}

span.MuiTouchRipple-root {
    display: none !important;
}
.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 120px;
    margin-bottom: 10px;
    cursor: pointer;
}
